import { Center, Flex, RingProgress, Stack } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  IconBox,
  IconCircleCheck,
  IconClipboard,
  IconHome,
  IconRulerMeasure,
  IconTruckDelivery,
  IconWash,
} from "@tabler/icons-react";
import { ReactElement, cloneElement } from "react";
import { useTranslation } from "react-i18next";
import { EOrderStatus } from "../interfaces/IOrder";
import classes from "../modules/orderPage.module.css";
import CardWrapper from "./general/CardWrapper";

interface IStatusCardProps {
  status: EOrderStatus;
  code: string;
}
const statuses = (t: any) => {
  return [
    {
      label: t(
        "v2.OrderPage.orderDetails.statusCard.statuses.pickedUpInProgress"
      ),
      value: EOrderStatus.PICKED_UP_IN_PROGRESS,
      step: 0,
      icon: <IconBox />,
    },
    {
      label: t("v2.OrderPage.orderDetails.statusCard.statuses.collected"),
      value: EOrderStatus.PICKED_UP,
      step: 1,
      icon: <IconBox />,
    },
    {
      label: t("v2.OrderPage.orderDetails.statusCard.statuses.processing"),
      value: EOrderStatus.IN_PROGRESS,
      step: 2,
      icon: <IconClipboard />,
    },
    {
      label: t("v2.OrderPage.orderDetails.statusCard.statuses.measuring"),
      value: EOrderStatus.MEASURED,
      step: 3,
      icon: <IconRulerMeasure />,
    },
    {
      label: t("v2.OrderPage.orderDetails.statusCard.statuses.washing"),
      value: EOrderStatus.WASHED,
      step: 4,
      icon: <IconWash />,
    },
    {
      label: t("v2.OrderPage.orderDetails.statusCard.statuses.delivering"),
      value: EOrderStatus.FOR_DELIVERY,
      step: 5,
      icon: <IconTruckDelivery />,
    },
    {
      label: t("v2.OrderPage.orderDetails.statusCard.statuses.ready"),
      value: EOrderStatus.FOR_DELIVERY_IN_HOUSE,
      step: 5,
      icon: <IconHome />,
    },
    {
      label: t("v2.OrderPage.orderDetails.statusCard.statuses.delivered"),
      value: EOrderStatus.DELIVERED,
      step: 6,
      icon: <IconCircleCheck />,
    },
  ];
};

export default function StatusCard({ status, code }: IStatusCardProps) {
  const isTablet = useMediaQuery("(max-width: 768px)");
  const isMobile = useMediaQuery("(max-width: 576px)");
  const { t } = useTranslation();
  const statusesLength = statuses(t).at(-1)?.step;
  const currentStatus = statuses(t).find((item) => item.value === status);

  const formatIcon = currentStatus?.icon
    ? cloneElement(currentStatus?.icon as ReactElement<any>, {
        size: isTablet ? 28 : 36,
        color: "var(--accent)",
        stroke: 2,
        style: {
          flexShrink: 0,
        },
      })
    : "";

  const percent = ((currentStatus?.step ?? 0) / (statusesLength ?? 0)) * 100;

  const returnResponsiveStyles = () => {
    let styles = {
      justify: "center",
      textAlign: "center",
    };
    if (isMobile) {
      return styles;
    } else if (isTablet) {
      styles = {
        justify: "flex-start",
        textAlign: "left",
      };
      return styles;
    } else {
      return styles;
    }
  };

  return (
    <CardWrapper
      style={{
        height: "100%",
      }}
    >
      <div className={classes.statusStack}>
        <Flex w={isTablet ? undefined : "100%"} justify="center">
          <RingProgress
            // @ts-ignore
            size={isTablet ? 150 : 250}
            sections={[
              {
                value: percent,
                color: "var(--accent)",
              },
              {
                value: 100 - percent,
                color: "var(--divider40)",
              },
            ]}
            label={
              <Center w="100%">
                <h2
                  className="h2"
                  style={{ textAlign: "center", fontWeight: 500 }}
                >
                  {currentStatus?.step}/{statusesLength}
                </h2>
              </Center>
            }
          />
        </Flex>
        <Stack w={isTablet ? undefined : "100%"} justify="center" spacing={4}>
          <h3
            className="h3"
            style={{
              // @ts-ignore
              textAlign: returnResponsiveStyles().textAlign,
              fontWeight: 700,
            }}
          >
            {code.toUpperCase()}
          </h3>
          <Flex
            w="100%"
            justify={returnResponsiveStyles().justify}
            align="center"
            gap="var(--xs)"
          >
            {formatIcon}
            <h3 style={{ color: "var(--accent)" }} className="h3">
              {currentStatus?.label}
            </h3>
          </Flex>
        </Stack>
      </div>
    </CardWrapper>
  );
}
