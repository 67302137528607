import { Avatar, Center, Divider, Flex, Stack } from "@mantine/core";
import { IconBrandWhatsapp, IconPhone, IconUser } from "@tabler/icons-react";
import classes from "../modules/orderPage.module.css";

import { useTranslation } from "react-i18next";

import ContactActionIcon from "./ContactActionIcon";
import DateStack from "./DateStack";

import moment from "moment";
import "moment/locale/hu";
import "moment/locale/pl";
import "moment/locale/ro";
import { useState } from "react";
import { EOrderStatus, IOrder } from "../interfaces/IOrder";
import { countryPrefix } from "../utils/dataArrays/countryData";
import CardWrapper from "./general/CardWrapper";
import RequestDateChangeModal from "./RequestDateChangeModal";

interface IInfoCardProps {
  order: IOrder;
  refetch: () => void;
}

export default function InfoCard({ order, refetch }: IInfoCardProps) {
  const { i18n, t } = useTranslation();
  const [isImageValid, setIsImageValid] = useState(true);
  const locale = i18n.language;
  moment.locale(locale);
  const prefix = countryPrefix.find(
    (item) => item.language === order.organizationLanguage?.toUpperCase()
  );
  const isOrderReady =
    order.status === EOrderStatus.FOR_DELIVERY ||
    order.status === EOrderStatus.FOR_DELIVERY_IN_HOUSE;

  return (
    <CardWrapper
      style={{
        paddingInline: 0,
        height: "100%",
      }}
    >
      <Stack w="100%" spacing="var(--sm)">
        <div className={classes.infoCardFirstRow}>
          <Stack spacing="var(--xs)">
            <p className="bt">
              {t("v2.OrderPage.orderDetails.infoCard.client")}
            </p>
            <Flex align="center" gap="var(--sm)">
              <Center
                style={{ borderRadius: 8 }}
                bg="var(--divider)"
                w={64}
                h={64}
              >
                <IconUser size={48} color="var(--bodyLight)" />
              </Center>
              <Stack spacing={4}>
                <p className="p">{order.name?.toUpperCase()}**</p>
                <p className="p" style={{ color: "var(--bodyLight)" }}>
                  {order.phone}
                </p>
              </Stack>
            </Flex>
          </Stack>
          <div className={classes.organizationSideStack}>
            <Flex justify="space-between" align="center" gap="var(--xl)">
              <p className="bt">
                {t("v2.OrderPage.orderDetails.infoCard.laundry")}
              </p>
              <Flex align="center" gap="var(--sm)">
                <ContactActionIcon
                  tooltipLabel={t(
                    "v2.OrderPage.orderDetails.infoCard.tooltips.call"
                  )}
                  icon={<IconPhone />}
                  to={`tel:${order.organizationPhone}`}
                />
                <ContactActionIcon
                  tooltipLabel={t(
                    "v2.OrderPage.orderDetails.infoCard.tooltips.message"
                  )}
                  icon={<IconBrandWhatsapp />}
                  to={`https://wa.me/${prefix?.prefix}${
                    order.organizationPhone
                  }?text=${encodeURIComponent(
                    t("v2.OrderPage.orderDetails.infoCard.whatsappMessage", {
                      code: order.generatedTicketId,
                    })
                  )}`}
                />
              </Flex>
            </Flex>
            <Stack w="100%" spacing="var(--xs)">
              <Flex align="center" gap="var(--sm)">
                <Center
                  style={{ borderRadius: 8 }}
                  bg="var(--divider)"
                  w={64}
                  h={64}
                >
                  {order.organizationLogo && isImageValid ? (
                    <img
                      onError={() => setIsImageValid(false)}
                      src={order.organizationLogo}
                      style={{
                        width: "100%",
                        borderRadius: 8,
                      }}
                      alt={t("ImageAlts.image")}
                    />
                  ) : (
                    <Avatar
                      styles={{
                        placeholder: {
                          color: "var(--bodyLight)",
                          background: "none",
                          textTransform: "uppercase",
                          fontSize: 24,
                        },
                      }}
                      w="100%"
                      h="100%"
                      radius={8}
                    >
                      {order.organizationName?.slice(0, 2)}
                    </Avatar>
                  )}
                </Center>
                <Stack spacing={4}>
                  <p className="p">{order.organizationName}</p>
                  <p className="p" style={{ color: "var(--bodyLight)" }}>
                    {order.organizationPhone}
                  </p>
                </Stack>
              </Flex>
            </Stack>
          </div>
        </div>
        <Divider color="var(--divider)" />
        <div className={classes.infoCardFirstRow}>
          <Stack spacing="var(--sm)">
            <p className="bt">
              {t("v2.OrderPage.orderDetails.infoCard.dates")}
            </p>
            <DateStack
              label={t(
                "v2.OrderPage.orderDetails.infoCard.datesLabels.collect"
              )}
              date={order.pickupDate}
              isVisible
            />
            <DateStack
              format="dddd DD.MM.YYYY"
              label={t(
                "v2.OrderPage.orderDetails.infoCard.datesLabels.delivery"
              )}
              date={order.aproximateDelivery!}
              isVisible={Boolean(order.aproximateDelivery)}
              timeRange={order.aproximateHourDelivery}
            />
            <DateStack
              label={t(
                "v2.OrderPage.orderDetails.infoCard.datesLabels.delivered"
              )}
              date={order.deliveryDate!}
              isVisible={Boolean(
                order.deliveryDate && order.status === EOrderStatus.DELIVERED
              )}
            />
            {order.deliveryDateChanged && (
              <p className="p" style={{ color: "var(--green)" }}>
                {t("v2.OrderPage.orderDetails.infoCard.requestSent")}
              </p>
            )}
          </Stack>
          {isOrderReady && order.abilityToAddPreferredDeliveryDate && (
            <RequestDateChangeModal
              isRouteDelivery={Boolean(order.aproximateHourDelivery)}
              refetch={refetch}
              id={order.generatedTicketId}
              details={order?.deliveryDateChanged}
            />
          )}
        </div>
      </Stack>
    </CardWrapper>
  );
}
