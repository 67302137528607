import { Divider, Flex, Stack } from "@mantine/core";
import {
  IconBrandFacebook,
  IconBrandLinkedin,
  IconMail,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SocialMediaBubble from "../../components/general/SocialMediaBubble";
import classes from "../../modules/layout.module.css";
import returnLocale from "../../utils/returnLocale";
import { WEBSITE_URL } from "../NavigationLayout";
import Logo from "./Logo";

const contactMethods = (t: any) => {
  return [
    {
      label: "contact@zarpet.net",
      icon: <IconMail />,
      type: "link",
      to: `mailto:contact@zarpet.net?subject=${encodeURIComponent(
        t("v2.Layout.footer.contactMessages.email")
      )}`,
    },
    {
      label: "Zarpet",
      icon: <IconBrandLinkedin color="var(--heading)" size={24} />,
      type: "link",
      to: "https://www.linkedin.com/company/zarpet/",
    },
  ];
};

const legalPages = (locale: string, t: any) => {
  return [
    {
      label: t("v2.Layout.footer.legalPages.termsAndConditions"),
      to: `${WEBSITE_URL}${locale}/termeni-si-conditii`,
    },
    {
      label: t("v2.Layout.footer.legalPages.privacyPolicy"),
      to: `${WEBSITE_URL}${locale}/politica-de-confidentialitate`,
    },
    {
      label: t("v2.Layout.footer.legalPages.cookiesPolicy"),
      to: `${WEBSITE_URL}${locale}/politica-de-cookies`,
    },
  ];
};

export default function Footer() {
  const { t, i18n } = useTranslation();
  const locale = returnLocale(i18n.language);

  return (
    <div
      style={{
        width: "100%",
        paddingTop: 60,
        backgroundColor: "var(--negative-elevation)",
        boxShadow: "var(--footer-inset-sh)",
        paddingBottom: "var(--lg)",
        marginTop: "var(--sections-gap)",
      }}
    >
      <div className="margin-container">
        <div className={classes.footerFlexContainer}>
          <Stack
            //  w="30%"
            spacing="var(--md)"
          >
            <Logo />
            <Flex align="center" gap="var(--sm)">
              <SocialMediaBubble
                icon={<IconBrandLinkedin />}
                to="https://www.linkedin.com/company/zarpet/"
              />
              <SocialMediaBubble
                icon={<IconBrandFacebook />}
                to="https://www.facebook.com/zarpetromania/"
              />
            </Flex>
          </Stack>
          <Stack spacing="var(--sm)">
            <h5
              className="h4"
              style={{ fontSize: 20, color: "var(--bodyLight)" }}
            >
              {t("v2.Layout.footer.contactLabel")}
            </h5>
            {contactMethods(t).map((method, i) => (
              <Flex gap="var(--xs)" align="center" key={i}>
                {method.icon}
                <Link className="link" target="_blank" to={method.to!}>
                  {method.label}
                </Link>
              </Flex>
            ))}
          </Stack>
          <Stack spacing="var(--sm)">
            <h5
              className="h4"
              style={{ fontSize: 20, color: "var(--bodyLight)" }}
            >
              {t("v2.Layout.footer.legalLabel")}
            </h5>
            {legalPages(locale, t).map((page, i) => (
              <Link key={i} target="_blank" className="link" to={page.to}>
                {page.label}
              </Link>
            ))}
          </Stack>
          {/* <div className={classes.footerInfoContainer}>
            <Stack spacing="var(--sm)">
              <h5
                className="h4"
                style={{ fontSize: 20, color: "var(--bodyLight)" }}
              >
                {t("v2.Layout.footer.pagesLabel")}
              </h5>
              {navigation(locale, t).map((item, i) => (
                <Link
                  target="_blank"
                  style={{ cursor: "pointer" }}
                  className="link"
                  key={i}
                  to={item.to}
                >
                  {item.label}
                </Link>
              ))}
            </Stack>
            <Stack spacing="var(--sm)">
              <h5
                className="h4"
                style={{ fontSize: 20, color: "var(--bodyLight)" }}
              >
                {t("v2.Layout.footer.contactLabel")}
              </h5>
              {contactMethods(t).map((method, i) => (
                <Flex gap="var(--xs)" align="center" key={i}>
                  {method.icon}
                  <Link className="link" target="_blank" to={method.to!}>
                    {method.label}
                  </Link>
                </Flex>
              ))}
            </Stack>
            <Stack spacing="var(--sm)">
              <h5
                className="h4"
                style={{ fontSize: 20, color: "var(--bodyLight)" }}
              >
                {t("v2.Layout.footer.legalLabel")}
              </h5>
              {legalPages(locale, t).map((page, i) => (
                <Link key={i} target="_blank" className="link" to={page.to}>
                  {page.label}
                </Link>
              ))}
            </Stack>
          </div> */}
        </div>
      </div>
      <Divider my="var(--md)" color="var(--divider40)" />
      <Flex justify="center" w="100%">
        <p className="p" style={{ color: "var(--grey)" }}>
          Copyright © 2024 Zarpet.net
        </p>
      </Flex>
    </div>
  );
}
