export interface IItem {
  id: string;
  name: string;
  quantity?: number;
  pricePerUnit: number;
  type: EOrderItemType;
  length?: number;
  width?: number;
  totalPriceItem: number;
}
export interface IOrder {
  id: string;
  organizationPhone: string;
  generatedTicketId: string;
  organizationLanguage?: string;
  organizationTimezone?: string;
  organizationCurrency?: string;
  countryPrefix?: string;
  status: EOrderStatus;
  name: string;
  phone: string;
  pickupDate: Date;
  deliveryDate?: Date;
  aproximateDelivery?: Date;
  feedbackSubmitted?: boolean;
  preferredDeliveryDate?: boolean;
  initialProductsNumber?: number;
  aproximateHourDelivery?: string;
  organizationLogo?: string;
  organizationName?: string;
  deliveryDateChanged?: {
    date: Date;
    reason: string;
    time: string;
  };
  feedback?: {
    rating: number;
    comment: string;
  };
  items?: IItem[];
  clientAgreedTerms?: boolean;
  organizationTermsAndConditions?: string;
  totalPrice?: number;
  restToMinimumOrder?: number;
  minimumOrder?: number;
  abilityToAddPreferredDeliveryDate?: boolean;
}

export enum EOrderStatus {
  PICKED_UP_IN_PROGRESS = "pickedUpInProgress",
  PICKED_UP = "pickedUp",
  IN_PROGRESS = "inProgress",
  MEASURED = "measured",
  WASHED = "washed",
  FOR_DELIVERY_IN_HOUSE = "forDeliveryInHouse",
  FOR_DELIVERY = "forDelivery",
  DELIVERED = "delivered",
}

export enum EOrderItemType {
  CARPET = "carpet",
  CLOTHES = "clothes",
}
