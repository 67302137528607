import { Center, Flex, Loader, Stack } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetOrder } from "../hooks/useGetOrder";
import FeedbackCard from "./components/FeedbackCard";
import InfoCard from "./components/InfoCard";
import ProductsSection from "./components/ProductsSection";
import SearchInput from "./components/SearchInput";
import StatusCard from "./components/StatusCard";
import TermsAndConditionsDrawer from "./components/TermsAndConditionsDrawer";
import { EOrderStatus, IOrder } from "./interfaces/IOrder";
import NotFoundIllustration from "./layout/components/NotFoundIllustration";
import classes from "./modules/orderPage.module.css";

export default function OrderDetailsPage() {
  const { paramId } = useParams();
  const [order, setOrder] = useState<IOrder>();
  const { data, isSuccess, refetch, isLoading } = useGetOrder();
  const { t } = useTranslation();
  useEffect(() => {
    if (isSuccess && data) {
      setOrder(data);
    }
  }, [isSuccess, data]);

  return isLoading ? (
    <Center w="100%" h="60vh">
      <Loader variant="dots" size="60px" color="yellow" />
    </Center>
  ) : (
    <div
      style={{
        paddingTop: "var(--nav-margin)",
        width: "100%",
      }}
      className="margin-container"
    >
      <Stack spacing="var(--xl)">
        <SearchInput withTitle />
        {isSuccess && order ? (
          <Stack spacing="var(--xl)">
            <TermsAndConditionsDrawer
              id={order.generatedTicketId}
              name={order.organizationName || ""}
              refetch={refetch}
              agreed={order.clientAgreedTerms}
              termsAndConditions={order.organizationTermsAndConditions}
            />
            <div>
              <h4
                className="h4"
                style={{
                  marginBottom: "var(--md)",
                }}
              >
                {t("v2.OrderPage.orderDetails.title")}
              </h4>
              <div className={classes.flexContainer}>
                <div className={classes.flexContainerChild1}>
                  <InfoCard refetch={refetch} order={order} />
                </div>
                <div className={classes.flexContainerChild2}>
                  <StatusCard code={paramId!} status={order.status} />
                </div>
              </div>
            </div>
            {order.status !== EOrderStatus.PICKED_UP && (
              <ProductsSection
                total={order.totalPrice || 0}
                currency={order.organizationCurrency || "RON"}
                products={order.items || []}
                restToMinimumOrder={order.restToMinimumOrder || 0}
                minimumOrder={order.minimumOrder || 0}
              />
            )}
            {order.status === EOrderStatus.DELIVERED && (
              <FeedbackCard refetch={refetch} order={order} />
            )}
          </Stack>
        ) : (
          <Center w="100%" h="40vh">
            <Stack spacing="var(--sm)" w="100%" justify="center">
              <Flex w="100%" justify="center">
                <NotFoundIllustration />
              </Flex>
              <h3
                className="h3"
                style={{
                  textAlign: "center",
                  color: "var(--divider)",
                }}
              >
                {t("v2.Layout.notFound")}
              </h3>
            </Stack>
          </Center>
        )}
      </Stack>
    </div>
  );
}
